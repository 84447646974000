import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import TopHeader from "./TopHeader";
import MainHeader from "./MainHeader";
import NavigationBar from "./NavigationBar";
import NotificationMessage from "./NotificationMessage";
import { getAdsectiondetails } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';
import TagManager from 'react-gtm-module'

const Header = () => {

  const dispatch = useDispatch();

  const faviconIcon = useSelector(
    ({ common }) => common.commonDetailsData.header_section.rsa_client_favicon
  );

  const headerSection = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  useEffect(() => {
    if (headerSection?.facebook_meta_pixel_code) {
      var head = document.getElementsByTagName("HEAD")[0];
      var script = document.createElement("noscript");
      var img = document.createElement("img");
      img.height = '1';
      img.width = '1';
      img.style = 'display:none';
      img.src =
        "https://www.facebook.com/tr?id=" + headerSection.facebook_meta_pixel_code + "&ev=PageView&noscript=1";
      script.async = true;
      script.appendChild(img);
      head.appendChild(script);
      /* const script = document.createElement('script');
      script.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${headerSection.facebook_meta_pixel_code}');
          fbq('track', 'PageView');
        `;
      document.body.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
          <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${headerSection.facebook_meta_pixel_code}&ev=PageView&noscript=1" />
        `;
      document.body.appendChild(noscript); */

    }

    if (headerSection?.geofencing_tracking_pixel_code) {
      const adsScript = document.createElement('script');
      adsScript.src = "https://js.adsrvr.org/up_loader.1.1.0.js";
      adsScript.type = "text/javascript";
      document.body.appendChild(adsScript);

      const trackingScript = document.createElement('script');
      trackingScript.type = "text/javascript";
      trackingScript.innerHTML = `
          ttd_dom_ready(function() {
            if (typeof TTDUniversalPixelApi === 'function') {
              var universalPixelApi = new TTDUniversalPixelApi();
              universalPixelApi.init("${headerSection.geofencing_tracking_pixel_advertiser_id}", ["${headerSection.geofencing_tracking_pixel_code}"], "https://insight.adsrvr.org/track/up");
            }
          });
        `;
      document.body.appendChild(trackingScript);
    }

  }, [headerSection]);

  const adsSectionData = useSelector(({ adsection }) => adsection.adsData);

  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  const getFaviconEl = () => {
    return document.getElementById("favicon");
  };

  useEffect(() => {
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = faviconIcon;
  }, [faviconIcon]);

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
    };
    adsSectionData && !adsSectionData.length && dispatch(getAdsectiondetails(body));
  }, [dispatch]); // eslint-disable-line


  useEffect(() => {

    if (headerSection.analytic_google_tag_manager_id) {
      const tagManagerArgs = {
        gtmId: `${headerSection.analytic_google_tag_manager_id}`
      }

      TagManager.initialize(tagManagerArgs)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <NotificationMessage />
      {/* 2024-03-08 Rao and valente email <TopHeader /> */}
      <MainHeader />
      <NavigationBar />
    </>
  );
};

export default Header;
